<template>
  <div class="text-sm">
     <br>
     <p class="p-3 text-lg font-bold "><span class="typcn typcn-edit"></span> Report Transaksi Penjualan </p>
     <hr class="mt-1 mb-1">
     <br>
     <br>
      <div class="row justify-content-center">
        <div class="col-12 mb-4 bg-white rounded-lg shadow shadow p-3">
            <p class="text-center p-3">Cari data Transaksi Penjualan</p>
            <div class="row justify-content-center">
              <div class="col-6">
                  <p class="pl-2 ">Pilih User/Kasir</p>
                  <v-select :options="kasirs" label="username" v-model="vdata.kasir" :reduce="e => e.id"></v-select>
                  <hr>
              </div>
              <div class="col-12"></div>
              <div class="col-4 text-center">
                    <div class="sm-form ">
                        <input type="date" id="tanggal1" name="tanggal1" class="form-control form-control-sm" placeholder="tanggal1" v-model="vdata.tanggal1" >
                    </div>
                    S/D
                    <div class="sm-form ">
                        <input type="date" id="tanggal2" name="tanggal2" class="form-control form-control-sm" placeholder="tanggal2" v-model="vdata.tanggal2" >
                    </div>
                    <hr>
                    <button type="button" @click="process=1;getData();" class="btn btn-sm btn-dark  "><span class="typcn typcn-zoom-in-outline"></span> Cari</button>
              </div>
              <div class="col-4 text-center">
                  <select class='form-control' v-model="vdata.pilihan">
                    <option value="1">Semua Data</option>
                  </select>                    
                  <hr>
                  <button  class="btn btn-sm btn-dark  " @click="process=2;getData();"><span class="typcn typcn-zoom-out-outline"></span> Cari</button>
              </div>
            </div>
          <!-- END BUTTON PART -->
        </div>
      </div>
    <!-- TABLE PART -->
    <div v-if="datanya.length>0" class="p-5 shadow rounded-lg">
      <div class="">
          <router-link to="/admin/laporan/penjualan/perincian">
            <button type="button" class="btn btn-sm btn-dark float-right mt-2"><span class="typcn typcn-document-add"></span> Cetak</button>
          </router-link>
        <!-- <vue-json-excel :data="datanya">
          <button type="button" class="btn btn-sm btn-dark">
            <span class="typcn typcn-chart-area"></span> Excel 
          </button>
        </vue-json-excel> -->
      </div>
        <br>
        <div class="rounded-lg shadow p-3 mb-4" v-for="(item, index) in nofakturs" :key="index+'nofakturs'">
            <br>
            <table class="table table-sm table-bordered">
                <tr>
                    <td class="font-bold">Tanggal</td>
                    <td class="font-bold">:</td>
                    <td class="font-bold">{{item.data[0].tanggal}}</td>
                     <td class="font-bold">Beli Lewat</td>
                    <td class="font-bold">:</td>
                    <td class="font-bold">{{item.data[0].beli}}</td>
                    <td colspan="3"></td>
                </tr>
                <tr>
                
                    <!-- <td class="font-bold">Supplier</td>
                    <td class="font-bold">:</td>
                    <td class="font-bold">{{item.data[0].supplier}}</td> -->
                    <td class="font-bold">Diinput Oleh</td>
                    <td class="font-bold">:</td>
                    <td class="font-bold">{{item.data[0].username}}</td>
                    <td class="font-bold">Nota</td>
                    <td class="font-bold">:</td>
                    <td class="font-bold">{{item.data[0].nofaktur}}</td>
                </tr>
                <tr>
                  <td class="font-bold">Pelanggan</td>
                    <td class="font-bold">:</td>
                    <td class="font-bold">{{item.data[0].pelanggan}}</td>
                </tr>
            </table>
            <hr>
            <table class="table table-sm table-bordered table-striped">
                <tr>
                    <td>Barcode</td>
                    <td>Barang/Produk</td>
                    <td>Jumlah</td>
                    <td>Diskon</td>
                    <td>Satuan</td>
                    <td>Harga</td>
                    <td>Total</td>
                </tr>
                <tr v-for="(item2, index2) in item.data" :key="index2+'table'">
                    <td>{{item2.barcode}}</td>
                    <td>{{item2.nama_produk}}</td>
                    <td>{{item2.jumlah}}</td>
                    <td>{{item2.diskon}}</td>
                    <td>{{item2.satuan}}</td>
                    <td>{{item2.harga}}</td>
                    <td>
                        {{item2.total}}
                    </td>
                </tr>
                <tr>
                    <td colspan="6">TOTAL</td>
                    <td colspan="4">{{item.data[0].total_seluruh}}</td>
                </tr>
            </table>
        </div>
        <div class="rounded-lg shadow p-3 mb-4">
          <table class="table table-sm table-bordered table-striped">
            <tr>
              <td class="font-bold">Total Semua Transaksi Penjualan</td>
              <td class="font-bold"> : </td>
              <td class="font-bold">{{seluruh}}</td>
            </tr>
          </table>
        </div>
      <!-- <Btables :tables="datanya"  :cari="['']" :hide="['']" @selected="ambil" ref="btable" /> -->
      <!-- <p class="font-bold text-xl">Total : Rp. {{formatRupiah(masuks)}}</p> -->
    </div>
    <!-- END TABLE PART -->
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
  components: {
    Btables,
  },
  layout:'app_shop_admin',
  data() {
    return {
      selected: false,
      vdata: {},
      btn: "tambah",
      datanya:[],
      masuks:0,
      process:1,
      nofakturs:[],
      kasirs:[],
      seluruh:0
    };
  },
  methods: {
    getData(){
        let that=this;
        this.datanya=[]
        let sql;
        if(!this.vdata.kasir){
          alert('silah kan pilih kasir terlebih dahulu')
          return 
        }
        if(this.process==1){
          sql=`SELECT tb1.id as no,tb1.tanggal,tb1.nota as nofaktur,tb2.nama_produk,tb2.barcode,tb1.jumlah,tb1.diskon,tb1.harga,tb1.total,tb3.username,tb1.beli,tb4.satuan, tb5.nama as pelanggan FROM app_kasir_keranjang tb1 left join app_kasir_produk tb2 on tb2.id=tb1.id_produk left join tbuser tb3 on tb3.id=tb1.id_user left join app_kasir_produk_satuan tb4 on tb4.id=tb1.satuan left join app_kasir_pelanggan tb5 on tb5.id=tb1.id_pelanggan    
              WHERE (tb1.tanggal_w >= '${this.vdata.tanggal1}' AND tb1.tanggal_w <= '${this.vdata.tanggal2}') AND tb1.id_user='${this.vdata.kasir}'`;
        }else if(this.process==2){
          if(this.vdata.pilihan==1){
            sql=`SELECT tb1.id as no,tb1.tanggal,tb1.nota as nofaktur,tb2.nama_produk,tb2.barcode,tb1.jumlah,tb1.diskon,tb1.harga,tb1.total,tb3.username,tb1.beli,tb4.satuan, tb5.nama as pelanggan FROM app_kasir_keranjang tb1 left join app_kasir_produk tb2 on tb2.id=tb1.id_produk left join tbuser tb3 on tb3.id=tb1.id_user left join app_kasir_produk_satuan tb4 on tb4.id=tb1.satuan left join app_kasir_pelanggan tb5 on tb5.id=tb1.id_pelanggan
            where tb1.id_user='${this.vdata.kasir}'`
          }
        }
      sdb.collection("app_kasir_stok_masuk",false).doc().select(sql).then(res => {
        this.datanya=res;
        this.nofakturs=res.map(e=>e.nofaktur)
        this.nofakturs= [...new Set(this.nofakturs)];
        this.nofakturs=this.nofakturs.map(e=>{
            return {
                nofaktur:e
            }
        })
         that.nofakturs.forEach((e,i)=>{ // 
            that.nofakturs[i].data=[];
            that.datanya.forEach(k=>{
                if(e.nofaktur==k.nofaktur){
                    that.nofakturs[i].data.push(k);
                }
            })
        })
        that.nofakturs.forEach(e=>{
            e.data=e.data.map(k=>{
                return{
                    ...k,
                    harga:that.formatRupiah(k.harga,'Rp. '),
                    tanggal:that.format(k.tanggal),
                    total:that.formatRupiah(parseInt(k.total),'Rp. '),
                    total2:parseInt(k.total),
                    total_seluruh:that.formatRupiah(e.data.reduce((j,n)=>{
                        return j+parseInt(n.total)
                    },0),'Rp. '),
                }
            })
            
        })
        that.seluruh=that.formatRupiah(that.nofakturs.reduce((e,n)=>{
              return e+parseInt(n.data.reduce((k,m)=>{
                return k+parseInt(m.total2)
              },0))
          },0),'Rp. ');
        that.$store.state.data.seluruh=that.seluruh
        console.log(that.nofakturs)
        this.masuks=res.reduce((e,n)=>{
          return e+(parseInt(n.harga)*parseInt(n.jumlah))
        },0)
          this.datanya.forEach((e,i)=>{
            e.no=i+1;
            e.tanggal=that.format(e.tanggal)
            e.harga=that.formatRupiah(e.harga,'Rp. ')
          })
          this.datanya.push({harga:this.formatRupiah(this.masuks,'Rp. ')})
        this.$store.state.data.report_masuk=res;
        this.$store.state.data.nofakturs=this.nofakturs;
        this.$forceUpdate();
      });
     
    },
    ambil(data) {
      this.vdata = data;
      this.btn = "update";
      this.$forceUpdate();
    },
     format(date) {
      return this.$datefns.format(date, "DD MMMM YYYY");
    },
    formatRupiah(angka, prefix){ // prefix disini taruh Rp. 
        angka=angka.toString();
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
        if(ribuan){
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    },
    submit() {
     this.getData();
    },
  },
  async mounted() {
     sdb.collection("tbuser",false).doc().select(`select * from tbuser where level IN ('Admin', 'SuperAdmin', 'Kasir','adminapotek','dokter','nurse','apoteker')`).then(res => {
          this.kasirs=res;
          this.$forceUpdate();
      });
      
  },
};
</script>